export const useCustomMediaQuery = () => {
  const isDesktop = useMediaQuery('(min-width: 1025px)')
  return {
    isMobile: useMediaQuery('(min-width: 0) and (max-width: 321px)'),
    isLargeMobile: useMediaQuery('(min-width: 322px) and (max-width: 415px)'),
    isTabletPortrait: useMediaQuery(
      '(min-width: 416px) and (max-width: 701px)',
    ),
    isSmallScreen: useMediaQuery('(min-width: 702px) and (max-width: 992px)'),
    isTabletLandscape: useMediaQuery(
      '(min-width: 993px) and (max-width: 1024px)',
    ),
    isDesktop: computed(() => (import.meta.server ? true : isDesktop.value)),
  }
}
